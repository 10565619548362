import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import Helmet from "react-helmet"
import SEO from "../components/seo"

const HostingPage = () => (
  <div>
    <SEO title="Web Hosting  Comparison - Our completely scalable Web Hosting Plans" /> 
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title">Our completely <span className="block text-indigo-600 xl:inline">scalable Web Hosting</span> Plans <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">Offer outstanding-quality web hosting solutions at reduced prices and we give you a ton of charge-free tools, which will help you build your website in minutes.</p>
            </div>
            <Helmet>
            <link rel="stylesheet" href="https://comparetables.duoservers.com/plain.css?table_bkg=FFFFFF&table_color=000000&plan_names_bkg=EEEEEE&features_hover=F9F9F9&order_bkg=CCCCCC&order_color=000000"/>
            
            <script src="https://code.jquery.com/jquery-2.2.4.min.js" integrity="sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44=" crossorigin="anonymous"></script>
            <script src="https://comparetables.duoservers.com/script.js?store_id=290832&service=hosting&style=plain&order_url=https://sheephostingbay.com/order-form/&order_text=Select Plan&container_id=compareTable&features_type=0"></script>

            </Helmet>
            <div id="compareTable" className="overflow-x-scroll shadow-lg"></div>
        </div>
        
    </div>

    <div className="py-32">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 w-full overflow-hidden text-center">
                <h2>Choose your data center</h2>
                <p>No matter which data center you select, you’ll get a 99.9% network uptime guarantee with all cloud web hosting packages.</p>
            </div>

            <div className="my-2 px-2 sm:w-1/5 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="US Data Center" src="https://sheephostingbay.duoservers.com/template2/img/us-server-hosting-small.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">US Data Center</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Steadfast, Chicago, IL</p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/5 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="UK Data Center" src="https://sheephostingbay.duoservers.com/template2/img/uk-server-hosting-small.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">UK Data Center</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Maidenhead, Berkshire, UK</p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/5 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="AU Data Center" src="https://sheephostingbay.duoservers.com/template2/img/au-server-hosting-small.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">AU Data Center</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Amaze, Sydney, Australia</p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/5 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="BG Data Center" src="https://sheephostingbay.duoservers.com/template2/img/bg-server-hosting-small.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">BG Data Center</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Telepoint, Sofia, Bulgaria</p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/5 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="Finland Data Center" src="https://sheephostingbay.duoservers.com/template2/img/fin-server-hosting-small.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Finland Data Center</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Ficolo/Pori, Finland</p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default HostingPage
